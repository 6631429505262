@import '../../styles/theme.scss';

.formSection {
    background-color: $grey;
    color: #ffffff;
    padding: 55px 0;
}

.socials__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    margin-right: 14px;
    transition: 0.3s;
    & svg {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        transform: scale(1.1);
    }
}

.form {
    display: contents;

    & :global(.subscribe-form-item__control--input-email) {
        width: 100%;
        padding: 15px;
        display: flex;
        text-align: center;
        border-radius: 6px;
        border: none;
        &::placeholder {
            color: #a4a4a4;
        }

        font-size: 18px;
        line-height: 21px;
        color: #a4a4a4;
    }

    & :global(.subscribe-form-item__btn) {
        transition: 0.3s;
        flex: 1;
        text-transform: uppercase;
        border: 3px solid $green;
        border-radius: 6px;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        width: 100%;
        padding: 13px;
    }
}

.button {
    border: 3px solid $green;
    border-radius: 15px;
    padding: 8px 8px;
    background-color: transparent;
    color: $green;
    cursor: pointer;
    transition: 0.4s;
    white-space: nowrap;
    text-align: center;
    &:hover {
        background-color: $green;
        color: #000000;
    }
}

.contents {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
}

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #545454;
    border-radius: 15px;
    padding: 30px 33px;
    color: #ffffff;
}

.contentGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 22px 28px;
    align-items: center;
    justify-items: stretch;
    margin-top: 10px;
}

.title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
}

.briffButton {
    font-size: 20px;
    text-align: center;
    margin-bottom: 12px;
    border: 3px solid #ed1c24;
    padding: 12px;
    border-radius: 40px;
    align-self: stretch;
    transition: 0.3s;
    font-weight: 700;
    &:hover {
        background-color: #ed1c24;
        color: #ffffff;
    }
}

.textLink {
    font-weight: 700;
}

.contactLinks {
    display: flex;
    align-items: center;
    gap: 30px;
    align-self: stretch;
}

.linkBtn {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    color: #ffffff;
    padding: 14px;
    flex: 1;
    transition: 0.3s;
    border: 3px solid #ed1c24;
    border-radius: 35px;
    &:hover {
        background: #ed1c24;
    }
}

.socialsBlock {
    display: contents;
}

.hashTag {
    text-align: center;
}

.socialsItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.socialsItem {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid $green;
    border-radius: 8px;
    padding: 10px;
    height: 54px;
    width: 66px;

    transition: 0.3s;
    & svg {
        height: 28px;
        width: 26px;
    }

    &:hover {
        background-color: $green;
        & svg {
            fill: #000000;
            transition: 0.3s;
        }
    }
}
