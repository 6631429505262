@import '../../styles/theme.scss';

.section {
    margin-bottom: 70px;
}

.sectionTitle {
    margin-bottom: 16px;
}

.block {
    display: flex;
    align-items: center;
    border-top: 3px solid #373735;
    padding: 16px 0;
    gap: 50px;

    &:last-child {
        border-bottom: 3px solid #373735;
    }

    & .textBlock {
        text-align: justify;
    }
}

.blockLeftPic {
    flex-direction: row-reverse;
    & .textContentWrapper {
        align-items: flex-end;
    }

    & .textBlock {
        text-align: justify;
    }
}

.textContentWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.blockTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $green;
    margin-bottom: 14px;
}

.blockDivider {
    height: 3px;
    width: 100%;
    background-color: #373735;
    margin: 10px 0 12px 0;
}

.textBlock {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 6px;

    + .textBlock {
        margin-top: 16px;
    }
}

.textTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    color: #ffffff;
}

.textBold {
    font-weight: 700;
}

.textBlockIdent {
    margin-top: 10px;
}

.picture {
    flex-shrink: 0;
}

.blockImg {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
