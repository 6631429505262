@import '../../styles/theme.scss';

.section {
    margin-bottom: 147px;
}

.sectionTitle {
    margin-bottom: 52px;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    width: 153px;
    min-height: 183px;
    padding: 10px 0;
    align-items: center;
    &:hover {
        & .icon {
            transform: scale(1.1);
        }
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    width: 110px;
    margin-bottom: 30px;
    transition: 0.3s;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $green;
    font-size: 18px;
    text-transform: uppercase;
}

.type {
    margin-bottom: 7px;
}

.name {
    text-align: center;
    font-weight: 700;
    margin-bottom: 7px;
}

.swiper {
    padding: 0 40px !important;
}

.prev,
.next {
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 100;
    &:hover {
        transform: translateY(-50%) scale(1.1);
    }
}

.next {
    left: unset;
    right: 0;
}
