@import '../../styles/theme.scss';

.section {
    margin-bottom: 128px;
}

.list {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(3, 1fr);
}

.item {
    display: flex;
    align-items: center;
}

.number {
    font-weight: 900;
    font-size: 48px;
    line-height: 62px;
    color: $green;
    margin-right: 12px;
}

.text {
    font-size: 21px;
    line-height: 27px;
    color: #ffffff;
}
