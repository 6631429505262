@import '../../styles/theme.scss';

.cardsList {
    gap: 20px;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.card {
    display: flex;
    border: 3px solid $green;
    align-items: center;
    justify-content: center;
    height: 158px;
}

.cardHovered {
    background-color: $green;
    & .front {
        display: none;
    }
    & .back {
        display: flex;
    }
}

.front {
    display: flex;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 25px;
    transition: 0.3s;
    & .title {
        text-transform: uppercase;
    }
}

.back {
    display: none;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $green;
    padding: 14px;
    transition: 0.3s;
    & .title {
        color: #000107;
        margin-right: 0;
        font-size: 16px;
    }
}

.title {
    font-weight: 700;
    font-size: 20px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    margin-right: 20px;
    text-align: center;
    color: #ffffff;
}

.question {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: $green;

    border-radius: 50%;
    font-weight: 500;
    font-size: 20px;
    line-height: 111.19%;
    color: #000107;
}
