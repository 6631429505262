@import '../../styles/theme.scss';

.capabilitiesSection {
    margin-bottom: 90px;
}

.capabilitiesTitle {
    margin-bottom: 25px;
}

.sliderWrapper {
    display: grid;
    gap: 22px;
    grid-template-columns: 774px auto;
    grid-auto-flow: revert;
}

.thumbsWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.thumbSlide {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    color: #ffffff;
    &:global(.swiper-slide-active) {
        color: $green;
        cursor: default;
        &:hover {
            .thumbImg {
                & img {
                    scale: 1;
                }
            }
        }
    }
    &:hover {
        .thumbImg {
            & img {
                scale: 1.1;
            }
        }
    }
}

.thumbImg {
    width: 177px;
    height: 100px;
    flex-shrink: 0;
    border: 3px solid $green;
    overflow: hidden;
    & img {
        transition: 0.3s;
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

.thumbTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.mainSlide {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: black;
}

.mainSlideTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $green;
}

.swiper-container1 {
    width: 100%;
    height: 100%;
}
.swiper-container2 {
    width: 100%;
    height: 100%;
}

.rotatedArrow {
    rotate: 180deg;
}

.slider__prev,
.slider__next {
    cursor: pointer;
    padding-left: 80px;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slider__prev:hover,
.slider__next:hover {
    & svg {
        transition: 0.3s;
        fill: white;
        scale: 1.1;
    }
}

.slider__prev:active,
.slider__next:active {
    & svg {
        transition: 0.3s;
        scale: 1;
        fill: white;
    }
}

.slider__thumbs {
    height: calc(434px - (12px * 2) - (35px * 2));
}

.mainSlider {
    height: 504px;
    width: 100%;
    order: -1;
}

.mainSlidePic {
    position: relative;
    width: 100%;
    height: 434px;
    overflow: hidden;
    flex-shrink: 0;
    border: 3px solid $green;
}
.mainSlidePic img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.iframe {
    width: 100%;
    height: 434px;
    background-size: cover;
    border: 3px solid $green;
    overflow: hidden;
    flex-shrink: 0;
    background-position: center;
}

.customPlayButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    &:hover {
        & .youTubeBtn {
            scale: 1.1;
        }
    }
    &:active {
        & .youTubeBtn {
            scale: 1;
        }
    }
}

.youTubeBtn {
    transition: 0.3s;
}

@media (max-width: 767.98px) {
    .slider__flex {
        flex-direction: column-reverse;
    }
    .slider__col {
        flex-direction: row;
        align-items: center;
        margin-right: 0;
        margin-top: 24px;
        width: 100%;
    }
    .slider__images {
        width: 100%;
    }
    .slider__thumbs {
        height: 100px;
        width: calc(100% - 96px);
        margin: 0 16px;
    }
    .slider__prev,
    .slider__next {
        height: auto;
        width: 32px;
    }
}
