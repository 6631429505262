@import '../../styles/theme.scss';

.service {
    margin-bottom: 102px;
}

.serviceSectionTitle {
    font-size: 30px;
}

.serviceList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -25px;
}

.serviceItem {
    width: 222px;
    height: 218px;
    padding: 0 20px;
    margin-right: 14px;

    &:nth-child(5n) {
        margin-right: 0;
    }
    margin-bottom: 19px;
    border: 3px solid $green;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        color: #000107;
        background-color: $green;
        & .serviceIcon {
            margin-bottom: 10px;
            transform: scale(1.1) translateY(-10px);
            & svg {
                fill: #000107;
            }
        }
    }
}

.serviceIcon {
    transition: 0.3s;
    height: 78px;
    width: 78px;
    overflow: hidden;
    margin-bottom: 30px;
    margin-bottom: 30px;
    & svg {
        transition: 0.3s;
        height: 100%;
        width: 100%;
    }
}

.serviceTitle {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
}

.block {
    margin-bottom: 12px;
}

.boldAccent {
    font-weight: 700;
    font-size: 20px;
    line-height: 111.19%;
}

.headingText {
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
}

.colorAccent {
    color: $green;
}

.popupContentWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 28px 39px;
    border: 2px solid $green;
    width: 1170px;
    margin: 60px 0;
    background-color: #000107;

    top: auto;
    left: auto;
    transform: none;
    position: relative;
    margin: 0 auto;
}

.header {
    display: flex;
    align-items: center;
    flex: 1;
    margin-bottom: 24px;
}

.icon {
    color: $green;
    height: 50px;
    width: 50px;
    margin-right: 24px;

    & svg {
        height: 100%;
        width: 100%;
        fill: $green;
    }
}

.title {
    font-weight: 700;
    font-size: 33px;
    line-height: 130%;
    text-transform: uppercase;
    color: $green;
    white-space: nowrap;
}

.closeIcon {
    margin-left: auto;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.1);
    }
    &:active {
        transform: scale(0.9);
    }
}

.text {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 18px;
}

.price {
    display: flex;
    align-items: center;
}

.priceTitle {
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    color: $green;
    margin-right: 18px;
}

.link {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 3px solid $green;

    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2em;
    font-variant: small-caps;
    color: $green;
    transition: 0.3s;

    &:hover {
        color: $yellow;
        border-color: $yellow;
        & .arrow {
            fill: $yellow;
        }
    }
}

.arrow {
    transition: 0.3s;
    margin-left: 18px;
    width: 52px;
    fill: $green;
}

.prices {
    margin-bottom: 20px;
}

.pics {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -21px;
}

.picWrapper {
    width: 356px;
    height: 243px;
    margin-right: 21px;
    margin-bottom: 21px;
    object-fit: cover;
    &:nth-child(3n) {
        margin-right: 0;
    }
}

.pic {
    height: 100%;
    width: 100%;
    display: block;
}

.tabs {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid white;
    color: white;
    width: 211px;
    height: 42px;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    margin-right: 20px;
    transition: 0.3s;
    &:hover {
        color: $yellow;
        border-color: $yellow;
    }
    &-active {
        color: $green;
        border-color: $green;
    }
}

.list {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-bottom: 12px;
}

.listItem {
    position: relative;
    margin-left: 10px;
    &::before {
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: $green;
    }
}

.rowBlock {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.divider {
    display: block;
}

.photos {
    margin-bottom: 30px;
    & :global(.slick-dots li button::before) {
        font-size: 60px;
    }
    & :global(.slick-slide) {
        width: 272px !important;
    }
    & :global(.slick-track) {
        align-items: flex-start;
        justify-content: flex-start;
    }
    & :global(.slick-dots) {
        position: absolute;
        bottom: -30px;

        display: block;

        width: 100%;
        padding: 0;
        margin: 0;

        list-style: none;

        text-align: center;
    }
}

.photoWrapper {
    width: 260px !important;
    height: 181px;
    overflow: hidden;
    margin-right: 8px;
}

.photo {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
